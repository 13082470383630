const AboutUs = () => {
	return (
		<>
			<h1>About Us</h1>
			<header>
				<h1>Welcome to <span>GoKonn</span>!</h1>
			</header>

			<main>
				<section>
					<p>At <span>GoKonn</span>, we're dedicated to bringing you a handpicked selection of
						unique and
						handcrafted products that inspire and delight. We understand the joy of discovering something
						truly
						special, and that's why we've gained approval from Etsy to showcase a curated collection of
						products
						sourced directly from Etsy sellers.</p>
				</section>

				<section>
					<h2>Our Mission</h2>
					<p>Our mission is to make your shopping experience seamless and enjoyable. Each item you see on our
						website
						has been carefully selected to embody the craftsmanship, creativity, and individuality that Etsy
						is
						renowned for.</p>
				</section>

				<section>
					<h2>Transparency and Honesty</h2>
					<p>We value transparency and honesty in everything we do. When you browse our website, you can trust
						that
						you're exploring a thoughtfully curated assortment of products approved by Etsy. When you find
						something
						that captures your heart, simply click on the product to be redirected to its listing on Etsy,
						where you
						can complete your purchase securely and directly from the artisan themselves.</p>
				</section>

				<section>
					<h2>Supporting Small Businesses</h2>
					<p>By shopping with us, you're not only discovering unique treasures but also supporting small
						businesses
						and independent creators worldwide. We're grateful for the opportunity to bring you closer to
						the
						artisans behind these incredible products, and we're committed to providing you with an
						exceptional
						shopping experience every step of the way.</p>
				</section>
			</main>

			<footer>
				<p>Thank you for choosing <span>[Your Website Name]</span> for your shopping needs. We're thrilled to
					have you
					here, and we can't wait to help you find your next cherished treasure.</p>
				<p>Happy shopping!</p>
				<p><span>Sabin Dongol, </span> Founder, <span>https://www.gokonn.com/</span></p>
			</footer>
		</>
	);
};

export default AboutUs;