import { Fragment, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as GoKonnLogo} from '../../assets/gokonn-logo.svg';
import { CartContext } from '../../contexts/cart.context';
import { signOutUser } from '../../utils/firebase/firebase.utils';
import CartIcon from '../../components/cart-icon/cart-icon.component';
import CartDropdown from '../../components/cart-dropdown/cart-dropdown.component';
import { selectCurrentUser } from '../../store/user/user.selector';
// import './navigation.styles.scss';
import { NavigationContainer, NavLinks, NavLink, LogoContainer } from './navigation.styles';

const Navigation = () => {
	const currentUser = useSelector(selectCurrentUser);
	const { isCartOpen } = useContext(CartContext);

	return (
		<Fragment>
			<NavigationContainer>
				<LogoContainer to='/'>
					<GoKonnLogo className='logo'/>
				</LogoContainer>
				<NavLinks>
					<NavLink className='nav-link' to='/shop'>
						SHOP
					</NavLink>
					<NavLink className='nav-link' to='/about-us'>
						ABOUT US
					</NavLink>
					<NavLink className='nav-link' to='/contact-us'>
						CONTACT US
					</NavLink>
					{
						currentUser ? (
							<NavLink as='span' onClick={signOutUser}>
								SIGN OUT
							</NavLink>)
							: (<NavLink className='nav-link' to='/auth'>
								SIGN IN
							</NavLink>)
					}
					<CartIcon/>
				</NavLinks>
				{isCartOpen && <CartDropdown/>}
			</NavigationContainer>
			<Outlet/>
		</Fragment>
	);
};

export default Navigation;