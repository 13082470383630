import React from 'react';
import Directory from '../../components/directory/directory.component';

const Home = () => {
	const categories = [
		{
			'id': 1,
			'title': 'accessories',
			'imageUrl': 'https://d17lrcfbj8vy0l.cloudfront.net/categories/accessories.jpg'
		},
		{
			'id': 2,
			'title': 'arts',
			'imageUrl': 'https://d17lrcfbj8vy0l.cloudfront.net/categories/arts.jpg'
		},
		{
			'id': 3,
			'title': 'babies',
			'imageUrl': 'https://d17lrcfbj8vy0l.cloudfront.net/categories/babies.jpg'
		},
		{
			'id': 4,
			'title': 'craft',
			'imageUrl': 'https://d17lrcfbj8vy0l.cloudfront.net/categories/craft.jpg'
		},
		{
			'id': 5,
			'title': 'home',
			'imageUrl': 'https://d17lrcfbj8vy0l.cloudfront.net/categories/home.jpg'
		}
	];
	return (
		<Directory categories={categories}/>
	);
};

export default Home;
